import { acceptHMRUpdate, defineStore } from "pinia";
import { type CheckList } from "@/models/check-list";
import { type Favorite } from "@/models/favorite";
import { type Alert } from "@/models/alert";
import { useApiFetch } from "~/composables/useApiFetch";
import { type Invite } from "~/models/invite";
import { type FetchError } from "ofetch";
import { type Trip } from "~/models/trip";
import type { User } from "~/models/user";
import type { AsyncData } from "#app";

export const useAuthStore = defineStore("UserStore", {
  persist: true,
  state: () => {
    return {
      user: undefined as User | undefined,
      isAuthenticated: false,
      lastAuthCheck: null as number | null,
      sessionLifetime: null as number | null,
      error: undefined as string | undefined,
      remember: false,
    };
  },

  actions: {
    async fetchCsrfToken() {
      let config = useRuntimeConfig();
      let url = "/sanctum/csrf-cookie";

      await $fetch(url, {
        baseURL: config.public.apiBase,
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      });
    },

    async login(
      email: string,
      password: string,
      remember: boolean,
      attempts: number = 0,
    ): Promise<void> {
      if (!useCookie("XSRF-TOKEN").value) {
        await this.fetchCsrfToken();
      }

      let { data, error } = (await useApiFetch(`/api/login`, {
        body: { email, password, remember },
        method: "POST",
      })) as AsyncData<any, FetchError>;

      if (attempts < 1 && error?.value?.statusCode === 419) {
        await this.fetchCsrfToken();
        return await this.login(email, password, remember, attempts + 1);
      }

      if (error?.value) {
        this.error = error.value.data.error;
        return console.error(error.value.data);
      }

      this.lastAuthCheck = new Date().getTime();
      this.isAuthenticated = true;
      this.user = data.value.data;
      this.sessionLifetime = parseInt(data.value.session_lifetime);
      this.remember = data.value.remember;
    },

    async logout() {
      //todo clear cookies after logout
      let { error } = (await useApiFetch("/api/logout", {
        method: "POST",
      })) as AsyncData<any, FetchError>;

      if (error?.value) return console.error(error.value);

      this.clearUserData();
    },

    async checkUserSession() {
      // if (!this.isAuthenticated || this.remember === true || this.lastAuthCheck === null) {
      //   return
      // }
      // Calculate the difference in milliseconds
      //const diffInMilliseconds = (new Date).getTime() - this.lastAuthCheck;
      // Convert the difference to minutes
      // const diffInMinutes = diffInMilliseconds / (1000 * 60);
      // console.log('Session length (minutes)', diffInMinutes);
      // // we don't need to check if the session is still within the lifetime window
      // if (this.sessionLifetime && diffInMinutes < this.sessionLifetime) {
      //   return
      // }

      await this.fetchUser();
    },

    async fetchUser() {
      let { data, error } = (await useApiFetch("/api/user")) as AsyncData<
        any,
        any
      >;
      if (error?.value || data.value === null) {
        this.clearUserData();
        navigateTo("/login");
        return;
      }

      this.lastAuthCheck = new Date().getTime();
      this.user = data.value;
      this.isAuthenticated = true;
    },

    async register(form: any) {
      if (!useCookie("XSRF-TOKEN").value) {
        await this.fetchCsrfToken();
      }
      const { data, error } = (await useApiFetch("/api/register", {
        body: form,
        method: "POST",
      })) as AsyncData<any, any>;

      if (error.value) return console.error(error.value);

      this.user = data.value.user;
      this.isAuthenticated = true;
      return data.value;
    },

    clearUserData() {
      const token = useCookie("XSRF-TOKEN");
      token.value = null;
      //todo clear other data
      this.user = undefined as unknown as User;
      this.isAuthenticated = false;
      this.lastAuthCheck = 0;
      this.sessionLifetime = -1;
      this.remember = false;
    },

    setCheckLists(check_lists: CheckList[]) {
      if (!this.user) return;
      this.user.check_lists = check_lists;
    },

    addCheckList(list: CheckList) {
      if (!this.user?.check_lists) return;
      this.user.check_lists.push(list);
    },

    removeCheckList(list_id: number) {
      if (!this.user?.check_lists) return;
      const idx = this.user.check_lists.findIndex((l) => l.id === list_id);
      this.user.check_lists.splice(idx, 1);
    },

    clearErrors() {
      this.error = undefined;
    },

    async fetchFavorites() {
      if (!this.user) return;

      const { data, error } = (await useApiFetch(
        "/api/favorites",
      )) as AsyncData<any, any>;

      if (error?.value) {
        this.clearUserData();
        console.error(error.value);
        navigateTo("/");
      }

      this.user.favorites = data.value?.data || [];
    },

    addFavoriteAlert(favorite: Favorite, alert: Alert) {
      if (!this.user?.favorites) return;
      //@ts-ignore
      let userFavorite = this.user.favorites.find((f) => f.id === favorite.id);
      if (!userFavorite?.favoriteable?.alerts) return;
      userFavorite.favoriteable.alerts.push(alert);
    },

    removeFavoriteAlert(favorite: Favorite, alert: Alert) {
      if (!this.user?.favorites) return;
      let userFavorite = this.user.favorites.find((f) => f.id === favorite.id);
      if (!userFavorite?.favoriteable?.alerts) return;
      let idx = userFavorite.favoriteable.alerts.findIndex(
        (a) => a.id === alert.id,
      );
      userFavorite.favoriteable.alerts.splice(idx, 1);
    },

    async fetchInvite(code: string) {
      let { data, error } = (await useApiFetch(
        `/api/invites/${code}`,
      )) as AsyncData<
        {
          invite: Invite;
          trip: Trip;
        },
        FetchError
      >;

      if (error.value) throw new Error(error.value?.message);

      return data.value;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
